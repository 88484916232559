import React, { Component } from "react";
import { Link } from "react-router-dom";

class Cabecalho extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false, // Estado inicial para o dropdown
        };

        // Bind da função para manter o contexto da classe
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    // Função para alternar o dropdown
    toggleDropdown() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    render() {
        const { dropdownOpen } = this.state; // Estado do dropdown

        return (
            <header id="header" className="topbar-dark logo-center">
                <div className="topbar">
                    <div className="container">
                        <div className="row menu_info">
                            <div className="col-md-6 menu_info_1">
                                <div className="topbar-left text-md-left text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <i className="fa fa-envelope-o"></i>contato@hgmultimarcas.com.br
                                        </li>
                                        <li>
                                            <i className="fa fa-clock-o"></i>Seg - Sex 8:00 - 18:00. Sab 9:00 - 12:00. Dom - Fechado.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 menu_info_2">
                                <div className="topbar-right text-md-right text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <i className="fa fa-phone"></i>(34) 3226-0000
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/hgmultimarcas.udi">
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/hg.multimarcas/">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.google.com/maps/place/HG+Multimarcas/@-18.8910855,-48.2617298,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445c270d059c3:0x8e8eae2068929658!8m2!3d-18.8910921!4d-48.2595311">
                                                <i className="fa fa-map-marker"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <nav id="menu" className="mega-menu">
                        <section className="menu-list-items">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <ul className="menu-logo">
                                            <li>
                                                <a href="index.html">
                                                    <img id="logo_dark_img" src="images/logo-dark.png" alt="logo" />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="menu-links">
                                            <li><Link to="/">INÍCIO</Link></li>
                                            <li><Link to="/estoque">ESTOQUE</Link></li>
                                            <li><Link to="/vendaseuveiculo">VENDA SEU VEÍCULO</Link></li>
                                            <li><Link to="/financiamento">FINANCIAMENTO</Link></li>
                                            <li><Link to="/contato">CONTATO</Link></li>
                                            <li className="dropdown" onClick={this.toggleDropdown}>
                                                <span>OUTROS SERVIÇOS</span>
                                                <ul className={`dropdown-menu ${dropdownOpen ? "open" : ""}`}>
                                                    <li>
                                                        <a
                                                            href="https://api.whatsapp.com/send?phone=5534992192266&text=Olá,%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20serviço%20de%20transferência%20de%20veículo."
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fa fa-whatsapp"></i>
                                                            Preciso transferir meu veículo
                                                        </a>
                                                        <a
                                                            href="https://api.whatsapp.com/send?phone=5534992192266&text=Olá,%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20serviço%20de%20cotação%20de%20seguro%20veicular."
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fa fa-whatsapp"></i>
                                                            ⁠Quero cotar seguro veicular
                                                        </a>
                                                        <a
                                                            href="https://api.whatsapp.com/send?phone=5534992192266&text=Olá,%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20a%20opção%20de%20adquirir%20um%20consórcio."
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fa fa-whatsapp"></i>
                                                            ⁠Quero adquirir um consórcio
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Cabecalho;
