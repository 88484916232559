import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer footer-black bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a className="facebook" href="https://www.facebook.com/hgmultimarcas.udi">
                      facebook <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      href="https://www.instagram.com/hg.multimarcas/"
                    >
                      Instagram <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a className="pinterest" href="https://www.google.com/maps/place/HG+Multimarcas/@-18.8910855,-48.2617298,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445c270d059c3:0x8e8eae2068929658!8m2!3d-18.8910921!4d-48.2595311">
                      Localização <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row footer_info">
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="about-content">
                <img
                  className="img-fluid"
                  id="logo-footer"
                  src="images/logo-light.png"
                  alt=""
                />
                <p>
                  Os melhores veículos você só encontra aqui na HG Multimarcas! Financiamos com ou sem entrada, com as 
                  menores taxas de juros do mercado! Aceitamos seu veículo de maior ou menor valor no negócio! Venha 
                  até nossa loja e faça um excelente negócio!
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="usefull-link">
                <h6 className="text-white">Informações de contato</h6>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-clock-o"></i>
                      Seg - Sex: 8:00 ás 18:00. Sab: 9:00 ás 12:00. Dom: Fechado.
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/maps/place/HG+Multimarcas/@-18.8910855,-48.2617298,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445c270d059c3:0x8e8eae2068929658!8m2!3d-18.8910921!4d-48.2595311">
                      <i className="fa fa-map-marker"></i>
                      Av. João Pinheiro, 3885 - Brasil, Uberlândia - MG, CEP 38400-714
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i>
                      (34) 3226-0000 
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=5534996571727&text=Ol%C3%A1%2C%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20seu%20produtos.">
                      <i className="fa fa-whatsapp"></i>
                      (34) 99219-2266
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope"></i>
                      contato@hgmultimarcas.com.br
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p>
                    ©Copyright 2022 HG Multimarcas por
                    <a
                      href="https://intermedioveiculos.com.br/"
                      target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12">
                <ul className="list-inline text-lg-right text-center">
                  <li><a href="contact-02.html">Contato </a></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;